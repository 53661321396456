
import ValidationMixin from "@/mixins/validation";
import { TicketTypes, UserTicket } from "@/store/modules/ticket/ticket.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const ticketX = namespace("Ticket");

@Component({})
export default class EditTicket extends Mixins(ValidationMixin) {
  @ticketX.State(TicketTypes.DELETE_TICKET_STATE)
  public deleteTicket!: DefaultState;

  @ticketX.State(TicketTypes.TICKET_DATA)
  public userTicket!: UserTicket;

  @ticketX.Mutation(TicketTypes.SET_DELETE_TICKET_DIALOG)
  public setDeleteTicket!: (deleteTicket: boolean) => void;

  @ticketX.Action(TicketTypes.DELETE_TICKET)
  public deleteUserTicket!: () => Promise<void>;

  public updateTicketStep = 1;

  public isValid = false;

  @Ref("form") public formRef!: Validator;

  closeDeleteTicketDialog(): void {
    this.setDeleteTicket(false);
  }
}
